.ph-list {
  background-color: var(--color-f8);
}

.ph-list .ph-list-content {
  margin: 0.37rem;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
}

.ph-list .ph-list-content > .title {
  font-size: 0.49rem;
  padding: var(--pading);
  line-height: 1.22rem;
  font-weight: bold;
  text-align: left;
}

.ph-list .ph-list-content .music-grid-item {
  margin-bottom: 0;
  padding-bottom: 0.24rem;
}

.ph-list .ph-list-content.recommend {
  margin-top: 0;
  box-shadow: 0 -3.9rem 0.24rem 0.24rem var(--color-white);
}

.ph-list .office {
  margin-top: 0;
  background-color: var(--color-f8);
}

.ph-list .office-list {
  margin-top: 0.24rem;
}

.ph-list .office-list-item {
  background-color: var(--color-white);
  margin-bottom: 0.49rem;
  padding: 0.37rem 0;
  border-radius: var(--border-radius);
}

.ph-list .office-list-item:last-child {
  margin-bottom: 0;
}

.ph-list .office-list-item .title {
  font-size: 0.49rem;
  margin-bottom: 0.24rem;
  font-weight: bold;
}

.ph-list .office-list-item .img {
  border-radius: var(--border-radius);
}

.ph-list .office-list-item .ph-grid.music-grid-item {
  margin-bottom: 0;
  padding: 0.12rem 0;
  border-bottom: 0;
}

.ph-list .office-list-item .ph-grid.music-grid-item .index {
  line-height: 0.39rem;
  color: var(--color-333);
  font-weight: normal;
  font-size: var(--size-14);
}

.ph-list .office-list-item .ph-grid.music-grid-item .index::after {
  content: '.';
  position: absolute;
}

.ph-list .office-list-item .ph-grid.music-grid-item .text .name {
  font-size: var(--size-14);
}

.ph-list .office-list-item .right {
  position: relative;
}

.ph-list .office-list-item .right .update-text {
  position: absolute;
  top: -0.24rem;
  right: 0.37rem;
  font-size: var(--size-12);
  color: var(--color-999);
}