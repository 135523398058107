.category-list {
  position: relative;
  overflow: hidden;
}

.category-list .cate-list {
  padding-right: 1.22rem;
  width: 100%;
  background-color: var(--color-white);
}

.category-list .cate-list .adm-jumbo-tabs-header {
  border-bottom: 0;
}

.category-list .cate-list-item {
  border-bottom: 0.05rem solid transparent;
  font-size: var(--size-14);
}

.category-list .cate-list-item .adm-jumbo-tabs-tab::after {
  content: '';
  position: absolute;
  bottom: 0.24rem;
  left: 50%;
  width: 0.73rem;
  height: 0.07rem;
  border-radius: 0.24rem;
  background-color: transparent;
  margin-left: -0.37rem;
}

.category-list .cate-list-item.active .adm-jumbo-tabs-tab {
  color: var(--adm-color-primary);
}

.category-list .cate-list-item.active .adm-jumbo-tabs-tab::after {
  background-color: var(--adm-color-primary);
}

.category-list .cate-list .more-icon {
  position: absolute;
  right: 0.37rem;
  top: 0.24rem;
  z-index: 100;
  background-color: var(--color-white);
  font-size: 0.54rem;
  box-shadow: 0 0 0 0.24rem var(--color-white);
}

.category-list .cate-content {
  padding-top: 0.37rem;
  height: calc(100vh - 2.44rem);
  overflow: hidden;
  overflow-y: auto;
}

.cate-menu .header {
  line-height: 1.22rem;
  border-bottom: 0.02rem solid var(--color-border);
}

.cate-menu-list {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 1.22rem);
  padding: 0.24rem 0.37rem;
}

.cate-menu-list-item {
  margin-bottom: 0.49rem;
}

.cate-menu-list .title {
  font-size: 0.39rem;
  margin-bottom: 0.24rem;
}

.cate-menu .title {
  font-size: 0.49rem;
}

.cate-menu .close-btn {
  font-size: 0.49rem;
  padding-right: 0.37rem;
}

.cate-menu .cat-text {
  font-size: var(--size-14);
  border-radius: 1.22rem;
  padding: 0.12rem 0.07rem;
  background-color: var(--color-f8);
  border: 0.02rem solid var(--color-border);
}

.cate-menu .cat-text.active {
  color: var(--color-white);
  background-color: var(--adm-color-primary);
}

.cate-menu .cat-text.active .icon {
  color: var(--color-white);
}

.cate-menu .cat-text .icon {
  color: var(--adm-color-primary);
  margin-right: 0.12rem;
}