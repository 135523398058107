.my-apps-list .icon {
  font-size: 0.78rem;
  --text-color: var(--adm-color-white);
  color: var(--adm-color-primary);
}

.my-apps-list .icon i {
  font-size: 0.78rem;
}

.my-apps-list .name {
  color: #666;
}

.my-apps .footer {
  margin-top: 0.37rem;
  padding-top: 0.27rem;
  border-top: 0.02rem solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.39rem;
}

.my-apps .footer .icon {
  margin-right: 0.24rem;
}