.my-records {
  padding-bottom: 0 !important;
}

.my-records .adm-tabs {
  overflow: hidden;
  position: sticky;
}

.my-records .play-list {
  max-height: calc(100vh - 2.56rem);
  padding: 0.24rem 0;
  text-align: center;
  overflow-y: auto;
}