.player {
  min-height: 100vh;
  background-color: var(--color-ccc);
}

.player .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-position: center;
  background-size: 300%;
  background-repeat: no-repeat;
  -webkit-filter: blur(0.73rem);
  filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  overflow: hidden;
  background-color: var(--color-ccc);
}

.player-header {
  color: var(--color-white);
  width: 100%;
  z-index: 10;
  padding: 0.24rem 0.12rem;
}

.player-header .name {
  padding-bottom: 0.12rem;
}

.player-header .singer {
  font-size: var(--size-14);
}

.player-content {
  width: 100%;
  position: relative;
  z-index: 10;
}

.player-content .img-cover {
  width: 100%;
  margin-top: 0.24rem;
}

.player-content .img-cover .img {
  border-radius: 100%;
  overflow: hidden;
  animation: rotate-animate 60s linear infinite;
  animation-play-state: running;
}

.player-content .img-cover .img.pause {
  animation-play-state: paused;
}

.player-content .lyric-section {
  width: 100%;
  margin-top: 0.24rem;
  position: relative;
}

.player-content .lyric {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
}

.player-content .lyric-item {
  width: 100%;
  padding: 0 0.37rem;
  line-height: 0.59rem;
  margin-bottom: 0.24rem;
  flex-wrap: wrap;
  word-break: break-all;
  font-size: var(--size-16);
  color: var(--color-ccc);
}

.player-content .lyric-item.active {
  font-size: var(--size-22);
  color: var(--color-white);
}

.player-content .actions {
  width: 100%;
  height: 1.95rem;
  padding: 0 0.37rem;
  justify-content: space-between;
}

.player-content .actions .icon {
  font-size: 0.78rem;
  color: var(--color-white);
}

.player-content .actions .icon.heart {
  font-size: 0.68rem;
}

.player-content .actions .icon.heart.active {
  color: var(--adm-color-primary);
}

.player-content .progress-bar {
  width: 100%;
  padding: 0 0.07rem;
}

.player-content .progress-bar .time {
  margin-top: 0.12rem;
  padding: 0 0.37rem;
  font-size: var(--size-14);
  color: var(--color-white);
}

.player-content .progress-bar .adm-slider-fill,
.player-content .progress-bar .adm-slider-track {
  border-radius: 0.12rem;
}

@keyframes rotate-animate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(1turn);
  }
}