.category-list {
  overflow: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.category-list-item {
  flex: 1;
  flex-basis: 1.46rem;
  color: var(--color-666);
}

.category-list-item .category-icon {
  color: var(--adm-color-primary);
  font-size: 0.78rem;
  border-radius: 100%;
  padding: 0.05rem 0.15rem;
  background: linear-gradient(to top, var(--color-ccc), var(--color-white));
}

.category-list-item .name {
  margin-top: 0.12rem;
  font-size: var(--size-12);
  line-height: var(--size-18);
}