.search-list {
  padding-bottom: 0 !important;
}

.search-list .adm-tabs {
  overflow: hidden;
  position: sticky;
}

.search-list .play-list {
  max-height: calc(100vh - 3.78rem);
  padding: 0.24rem 0 0;
  text-align: center;
  overflow-y: auto;
}

.search-list .play-list .type-2.music-grid-item .img {
  width: 5.37rem;
  border-radius: 0.12rem;
}

.search-list .play-list .type-2.music-grid-item .play-count {
  right: auto;
  left: 0.12rem;
}