.music-commit-item .top {
  font-size: var(--size-14);
}

.music-commit-item .top .avatar {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.music-commit-item .top .userinfo {
  padding-right: 0.37rem;
}

.music-commit-item .top .time {
  font-size: var(--size-12);
  color: var(--color-999);
}

.music-commit-item .top .nickname {
  margin-bottom: 0.12rem;
  color: var(--color-666);
}

.music-commit-item .bottom {
  padding-right: 0.37rem;
  border-bottom: 0.02rem solid var(--color-border);
  padding-bottom: 0.24rem;
  margin-left: 1.22rem;
}

.music-commit-item .bottom .repeat,
.music-commit-item .bottom .content {
  font-size: var(--size-14);
}

.music-commit-item .bottom .content {
  color: var(--color-333);
  line-height: 0.54rem;
  text-align: justify;
  padding: 0.12rem 0;
}

.music-commit-item .bottom .repeat {
  color: var(--adm-color-info);
  color: #34acdb;
}