@import url(//at.alicdn.com/t/c/font_3971272_x6shjed0ac.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root,
.music {
  height: 100vh;
  overflow: hidden;
}
:root:root {
  --primary-color: #333;
  --adm-color-primary: #f54040;
  --color-333: #333;
  --color-666: #666;
  --color-999: #999;
  --color-ccc: #ccc;
  --color-f5: #f5f5f5;
  --color-f8: #f8f8f8;
  --color-e8: #e8e8e8;
  --color-border: #e8e8e8;
  --color-white: #fff;
  --border-radius: 0.2rem;
  --bg-color: #282c34;
  --size-12: 0.29rem;
  --size-14: 0.34rem;
  --size-16: 0.39rem;
  --size-18: 0.44rem;
  --size-20: 0.49rem;
  --size-22: 0.54rem;
  --size-24: 0.59rem;
  --height: 1.22rem;
  --pading: 0 0.37rem;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

p,
ul,
li,
ol,
dl,
dt {
  padding: 0;
  margin: 0;
}

/*对齐，浮动*/

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.pd16 {
  padding: 0.39rem !important;
}

.m0 {
  margin: 0 !important;
}

.m16 {
  margin: 0.39rem !important;
}

.mr16 {
  margin-right: 0.39rem !important;
}

.mt16 {
  margin-top: 0.39rem !important;
}

.mb16 {
  margin-bottom: 0.39rem !important;
}

.ml16 {
  margin-left: 0.39rem !important;
}

.text-j {
  text-align: justify;
}

/*文字超出隐藏*/

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsisTwo {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsisOne {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsisOne1 {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

/*flex 横向 布局*/

.flexbox-h {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
}

/*flex 纵向 布局*/

.flexbox-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.align-c {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.just-c {
  -webkit-box-justify-content: center;
  -moz-box-justify-content: center;
  -ms-flex-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.just-between {
  -webkit-box-justify-content: space-between;
  -moz-box-justify-content: space-between;
  -ms-flex-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.just-around {
  -webkit-box-justify-content: space-around;
  -moz-box-justify-content: space-around;
  -ms-flex-justify-content: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  width: 0%;
}

.flex2 {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  flex: 2;
  width: 0%;
}

.flex3 {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  flex: 3;
  width: 0%;
}

.flex4 {
  -webkit-box-flex: 4;
  -webkit-flex: 4;
  flex: 4;
  width: 0%;
}
.swiper {
  width: calc(100% - 0.73rem);
  margin: 0 0.37rem 0.37rem;
}

.swiper-item {
  min-height: 2.93rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.17rem;
  user-select: none;
  position: relative;
}

.swiper-item .title {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0.37rem;
  border-radius: var(--border-radius) 0 var(--border-radius) 0;
  font-size: var(--size-12);
  line-height: var(--size-20);
  color: var(--color-white);
  background-color: var(--primary-color);
}
.category-list {
  overflow: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.category-list-item {
  flex: 1;
  flex-basis: 1.46rem;
  color: var(--color-666);
}

.category-list-item .category-icon {
  color: var(--adm-color-primary);
  font-size: 0.78rem;
  border-radius: 100%;
  padding: 0.05rem 0.15rem;
  background: linear-gradient(to top, var(--color-ccc), var(--color-white));
}

.category-list-item .name {
  margin-top: 0.12rem;
  font-size: var(--size-12);
  line-height: var(--size-18);
}
.music-grid-item {
  position: relative;
  margin-bottom: 0;
}

.music-grid-item.active .text .name,
.music-grid-item.active .text .description {
  color: var(--adm-color-primary) !important;
}

.music-grid-item .name {
  width: 100%;
  color: var(--color-333);
  line-height: 0.44rem;
  font-size: var(--size-14);
  margin-top: 0.24rem;
  text-align: justify;
}

.music-grid-item .img {
  box-shadow: 0 -0.05rem 0.24rem 0.07rem var(--color-ccc);
  border-radius: 0.24rem;
  overflow: hidden;
  width: auto;
  height: auto;
  min-height: 1.71rem;
  background-color: var(--color-ccc);
}

.music-grid-item .img .adm-image-tip {
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item .tag {
  flex-basis: 0.49rem;
  color: var(--color-999);
}

.music-grid-item .play-count {
  position: absolute;
  right: 0.24rem;
  top: 0.12rem;
  border-radius: var(--border-radius);
  padding: 0 0.12rem;
  font-size: var(--size-12);
  color: var(--color-white);
  background: var(--color-999);
}

.music-grid-item .play-count .play-icon {
  margin-right: 0.05rem;
  font-size: 0.29rem;
}

.music-grid-item.type-1 .img {
  width: 100%;
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item.type-1 .img .adm-image-tip {
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item.type-2 {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.12rem;
  border-bottom: 0.02rem solid var(--color-border);
}

.music-grid-item.type-2 .text {
  float: left;
  max-width: 8.29rem;
}

.music-grid-item.type-2 .text .name {
  margin-top: 0;
  line-height: 0.54rem;
  font-size: var(--size-18);
  color: var(--color-333);
}

.music-grid-item.type-2 .text .description {
  font-size: var(--size-12);
  vertical-align: middle;
  color: var(--color-999);
}

.music-grid-item.type-2 .img {
  float: left;
  margin-right: 0.24rem;
  min-height: 0.98rem;
  width: auto;
  height: auto;
}

.music-grid-item.type-2 .index {
  float: left;
  line-height: 0.98rem;
  font-weight: bold;
  font-size: var(--size-16);
  color: var(--color-999);
  margin-right: 0.24rem;
}

.music-grid .adm-grid-item:last-child .music-grid-item.type-2 {
  border-bottom: 0;
}
.home {
  align-items: flex-start;
}

.home .customIndicator {
  position: absolute;
  bottom: 0.15rem;
  right: 0.15rem;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.1rem 0.2rem;
  color: #ffffff;
  border-radius: 0.1rem;
  user-select: none;
}

.home > .title {
  text-align: left;
  font-size: 0.44rem;
  padding: 0 0.37rem;
}

.home .customSkeleton {
  --width: calc(100% - 0.73rem);
  margin: 0 0.37rem;
  --height: 2.44rem;
  --border-radius: 0.2rem;
}
.category-list {
  position: relative;
  overflow: hidden;
}

.category-list .cate-list {
  padding-right: 1.22rem;
  width: 100%;
  background-color: var(--color-white);
}

.category-list .cate-list .adm-jumbo-tabs-header {
  border-bottom: 0;
}

.category-list .cate-list-item {
  border-bottom: 0.05rem solid transparent;
  font-size: var(--size-14);
}

.category-list .cate-list-item .adm-jumbo-tabs-tab::after {
  content: '';
  position: absolute;
  bottom: 0.24rem;
  left: 50%;
  width: 0.73rem;
  height: 0.07rem;
  border-radius: 0.24rem;
  background-color: transparent;
  margin-left: -0.37rem;
}

.category-list .cate-list-item.active .adm-jumbo-tabs-tab {
  color: var(--adm-color-primary);
}

.category-list .cate-list-item.active .adm-jumbo-tabs-tab::after {
  background-color: var(--adm-color-primary);
}

.category-list .cate-list .more-icon {
  position: absolute;
  right: 0.37rem;
  top: 0.24rem;
  z-index: 100;
  background-color: var(--color-white);
  font-size: 0.54rem;
  box-shadow: 0 0 0 0.24rem var(--color-white);
}

.category-list .cate-content {
  padding-top: 0.37rem;
  height: calc(100vh - 2.44rem);
  overflow: hidden;
  overflow-y: auto;
}

.cate-menu .header {
  line-height: 1.22rem;
  border-bottom: 0.02rem solid var(--color-border);
}

.cate-menu-list {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 1.22rem);
  padding: 0.24rem 0.37rem;
}

.cate-menu-list-item {
  margin-bottom: 0.49rem;
}

.cate-menu-list .title {
  font-size: 0.39rem;
  margin-bottom: 0.24rem;
}

.cate-menu .title {
  font-size: 0.49rem;
}

.cate-menu .close-btn {
  font-size: 0.49rem;
  padding-right: 0.37rem;
}

.cate-menu .cat-text {
  font-size: var(--size-14);
  border-radius: 1.22rem;
  padding: 0.12rem 0.07rem;
  background-color: var(--color-f8);
  border: 0.02rem solid var(--color-border);
}

.cate-menu .cat-text.active {
  color: var(--color-white);
  background-color: var(--adm-color-primary);
}

.cate-menu .cat-text.active .icon {
  color: var(--color-white);
}

.cate-menu .cat-text .icon {
  color: var(--adm-color-primary);
  margin-right: 0.12rem;
}
.play-list {
  padding-bottom: 0 !important;
}

.play-list-item {
  border-bottom: 0.05rem solid transparent;
  padding-bottom: 0.12rem;
  position: relative;
}

.play-list-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0.73rem;
  height: 0.07rem;
  border-radius: 0.24rem;
  background-color: transparent;
  margin-left: -0.37rem;
}

.play-list-item.active {
  color: var(--adm-color-primary);
}

.play-list-item.active::after {
  background-color: var(--adm-color-primary);
}
.song-list {
  padding-bottom: 0 !important;
}

.song-list .cover-main {
  margin-bottom: 0.37rem;
  min-height: 10rem;
  position: relative;
}

.song-list .cover-main .cover-text {
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 0.37rem;
  width: 100%;
  color: var(--color-white);
  font-size: var(--size-12);
}

.song-list .cover-main .cover-text .line {
  width: 50%;
  color: var(--color-ccc);
  margin: 0 auto;
  margin-bottom: 0.12rem;
}

.song-list .cover-main .cover-text .name {
  font-size: var(--size-20);
  font-weight: bolder;
  margin-bottom: 0.24rem;
}

.song-list .cover-main .cover-text .description {
  margin-top: 0.37rem;
  color: var(--color-ccc);
}

.song-list .cover-main .cover-bg {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: rgba(117, 117, 117, 0.8);
}

.song-list .cover-main .img {
  width: auto;
  height: 100%;
  position: relative;
  z-index: 2;
}

.song-list .cover-main.custom-cover {
  min-height: 3.66rem;
  padding: 0 0 0 0.73rem;
  overflow: hidden;
}

.song-list .cover-main.custom-cover .cover-bg {
  border-radius: 0 0 50% 50%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  background-color: var(--color-ccc);
}

.song-list .cover-main.custom-cover .img {
  z-index: 10;
  width: 2.44rem;
  height: 2.44rem;
  border-radius: var(--border-radius);
}

.song-list .cover-main.custom-cover .cover-text {
  flex: 1;
  text-align: left;
  position: relative;
}

.song-list .cover-main.custom-cover .cover-text .line {
  display: none;
}

.song-list .cover-main.custom-cover .cover-text .name {
  font-size: var(--size-16);
}
.ph-list {
  background-color: var(--color-f8);
}

.ph-list .ph-list-content {
  margin: 0.37rem;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
}

.ph-list .ph-list-content > .title {
  font-size: 0.49rem;
  padding: var(--pading);
  line-height: 1.22rem;
  font-weight: bold;
  text-align: left;
}

.ph-list .ph-list-content .music-grid-item {
  margin-bottom: 0;
  padding-bottom: 0.24rem;
}

.ph-list .ph-list-content.recommend {
  margin-top: 0;
  box-shadow: 0 -3.9rem 0.24rem 0.24rem var(--color-white);
}

.ph-list .office {
  margin-top: 0;
  background-color: var(--color-f8);
}

.ph-list .office-list {
  margin-top: 0.24rem;
}

.ph-list .office-list-item {
  background-color: var(--color-white);
  margin-bottom: 0.49rem;
  padding: 0.37rem 0;
  border-radius: var(--border-radius);
}

.ph-list .office-list-item:last-child {
  margin-bottom: 0;
}

.ph-list .office-list-item .title {
  font-size: 0.49rem;
  margin-bottom: 0.24rem;
  font-weight: bold;
}

.ph-list .office-list-item .img {
  border-radius: var(--border-radius);
}

.ph-list .office-list-item .ph-grid.music-grid-item {
  margin-bottom: 0;
  padding: 0.12rem 0;
  border-bottom: 0;
}

.ph-list .office-list-item .ph-grid.music-grid-item .index {
  line-height: 0.39rem;
  color: var(--color-333);
  font-weight: normal;
  font-size: var(--size-14);
}

.ph-list .office-list-item .ph-grid.music-grid-item .index::after {
  content: '.';
  position: absolute;
}

.ph-list .office-list-item .ph-grid.music-grid-item .text .name {
  font-size: var(--size-14);
}

.ph-list .office-list-item .right {
  position: relative;
}

.ph-list .office-list-item .right .update-text {
  position: absolute;
  top: -0.24rem;
  right: 0.37rem;
  font-size: var(--size-12);
  color: var(--color-999);
}
.music-commit-item .top {
  font-size: var(--size-14);
}

.music-commit-item .top .avatar {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.music-commit-item .top .userinfo {
  padding-right: 0.37rem;
}

.music-commit-item .top .time {
  font-size: var(--size-12);
  color: var(--color-999);
}

.music-commit-item .top .nickname {
  margin-bottom: 0.12rem;
  color: var(--color-666);
}

.music-commit-item .bottom {
  padding-right: 0.37rem;
  border-bottom: 0.02rem solid var(--color-border);
  padding-bottom: 0.24rem;
  margin-left: 1.22rem;
}

.music-commit-item .bottom .repeat,
.music-commit-item .bottom .content {
  font-size: var(--size-14);
}

.music-commit-item .bottom .content {
  color: var(--color-333);
  line-height: 0.54rem;
  text-align: justify;
  padding: 0.12rem 0;
}

.music-commit-item .bottom .repeat {
  color: var(--adm-color-info);
  color: #34acdb;
}
.userinfo-content {
  font-size: var(--size-14);
}

.userinfo-content .avatar {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.userinfo-content .userinfo {
  padding-right: 0.37rem;
}

.userinfo-content .time {
  font-size: var(--size-12);
  color: var(--color-999);
}

.userinfo-content .nickname {
  margin-bottom: 0.12rem;
  color: var(--color-666);
}
.commit-list {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0!important;
}

.commit-list-top {
  width: 100%;
  padding: 0.12rem 0.37rem;
  font-size: var(--size-14);
  border-top: 0.02rem solid var(--color-border);
  border-bottom: 0.02rem solid var(--color-border);
  background-color: var(--color-white);
}

.commit-list-top .img {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.commit-list-top .name {
  line-height: 1.5;
  margin-right: 0.12rem;
}

.commit-list-top .singer {
  margin-left: 0.12rem;
  color: var(--color-999);
}

.commit-list .music-commit {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.commit-list .adm-list-default .adm-list-body {
  border-top: 0;
  border-bottom: 0;
}

.commit-list .adm-list {
  --padding-left: 0.37rem !important;
}

.commit-list .adm-list .adm-list-item .adm-list-item-content {
  padding-right: 0;
  border-top: 0;
}

.commit-list .adm-list .adm-list-item .adm-list-item-content .adm-list-item-content-main {
  padding-bottom: 0;
}

.commit-list.repeat {
  height: 70vh;
}

.commit-list.repeat .repeated-user .adm-list-item {
  border-bottom: 0.24rem solid var(--color-f8);
}

.commit-list.repeat .music-commit-item .bottom {
  border-bottom: 0;
}
.search {
  height: 100%;
}

.search.hidden {
  overflow: hidden !important;
}

.search-detail {
  padding-bottom: 0 !important;
}
.search-list {
  padding-bottom: 0 !important;
}

.search-list .adm-tabs {
  overflow: hidden;
  position: sticky;
}

.search-list .play-list {
  max-height: calc(100vh - 3.78rem);
  padding: 0.24rem 0 0;
  text-align: center;
  overflow-y: auto;
}

.search-list .play-list .type-2.music-grid-item .img {
  width: 5.37rem;
  border-radius: 0.12rem;
}

.search-list .play-list .type-2.music-grid-item .play-count {
  right: auto;
  left: 0.12rem;
}
.my {
  position: relative;
  padding-top: 0.61rem;
}
.my {
  position: relative;
  padding-top: 0.61rem;
}

.my-play-list {
  width: 100%;
}

.my-play-list .list {
  width: 100%;
  margin-bottom: 0.37rem;
  border-radius: 0.29rem;
  box-shadow: 0 0 0.24rem #e8e8e8;
  overflow: hidden;
}

.my-play-list .list .adm-list-header {
  background-color: #fff;
  text-align: left;
}

.my-play-list .list-item {
  text-align: left;
  width: 100%;
}

.favirous {
  margin-top: 0.39rem;
  padding: 0 0.29rem;
}

.favirous .adm-list,
.favirous .adm-list-item,
.favirous .adm-list-item-content {
  --border-top: 0;
  --border-bottom: 0;
  --border-inner: 0;
  border-top: 0;
}

.favirous .adm-list .adm-list-item-content-main .adm-list-item-description,
.favirous .adm-list-item .adm-list-item-content-main .adm-list-item-description,
.favirous .adm-list-item-content .adm-list-item-content-main .adm-list-item-description {
  margin-top: 0.12rem;
}

.tabsContainer {
  position: -webkit-sticky;
  position: sticky;
  top: -0.66rem;
  z-index: 100;
  padding: 0 0.29rem;
  margin: 0.39rem 0 0.07rem;
  background: var(--color-f8);
}

.tabsContainer .adm-tabs {
  overflow: hidden;
  border-radius: 0.2rem;
}

.tabsContainer .adm-tabs .adm-tabs-header {
  border-bottom: 0;
}

.tabsContainer .adm-tabs .adm-tabs-tab-wrapper.active {
  color: var(--adm-color-primary);
}
.my-userinfo .avatar {
  position: absolute;
  top: -1.1rem;
}

.my-userinfo .avatar .adm-avatar.adm-image {
  width: 1.76rem;
  height: 1.76rem;
}

.my-userinfo .info {
  margin: 0.88rem 0 0.2rem;
}

.my-userinfo .info .name {
  font-size: 0.54rem;
  margin-right: 0.24rem;
}

.my-userinfo .others > div {
  margin: 0 0.24rem;
  font-size: 0.34rem;
  color: #999;
}

.my-userinfo .others > div span {
  margin-right: 0.12rem;
}
.my-apps-list .icon {
  font-size: 0.78rem;
  --text-color: var(--adm-color-white);
  color: var(--adm-color-primary);
}

.my-apps-list .icon i {
  font-size: 0.78rem;
}

.my-apps-list .name {
  color: #666;
}

.my-apps .footer {
  margin-top: 0.37rem;
  padding-top: 0.27rem;
  border-top: 0.02rem solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 0.39rem;
}

.my-apps .footer .icon {
  margin-right: 0.24rem;
}
.my-records {
  padding-bottom: 0 !important;
}

.my-records .adm-tabs {
  overflow: hidden;
  position: sticky;
}

.my-records .play-list {
  max-height: calc(100vh - 2.56rem);
  padding: 0.24rem 0;
  text-align: center;
  overflow-y: auto;
}
.mv-detail {
  background-color: black;
}

.mv-detail .header {
  color: var(--adm-color-white);
}

.mv-detail .content {
  margin-top: 2.93rem;
  justify-content: space-between;
}

.mv-detail .bottom {
  width: 100%;
}

.mv-detail .info,
.mv-detail .text {
  width: 100%;
  padding: 0 0.37rem;
  text-align: left;
  margin-bottom: 0.24rem;
  justify-content: flex-start;
  line-height: 0.44rem;
  font-size: 0.39rem;
  color: var(--adm-color-white);
}

.mv-detail .info p,
.mv-detail .text p {
  margin-bottom: 0.12rem;
}

.mv-detail .info p.desc,
.mv-detail .text p.desc {
  max-height: 9.76rem;
}

.mv-detail .info .tag,
.mv-detail .text .tag {
  margin-right: 0.12rem;
}

.mv-detail .info .artistName,
.mv-detail .text .artistName {
  margin-left: 0.24rem;
}
.menu {
  padding-bottom: 0.24rem;
}

.menu-main {
  height: calc(100vh - 1.22rem);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.24rem;
  background-color: var(--color-f8);
}

.menu .user-info {
  height: 1.22rem;
  color: var(--color-333);
  font-size: var(--size-16);
  padding: 0 0.29rem;
  background-color: var(--color-white);
}

.menu .vip {
  margin: 0.37rem;
  color: #d3b78d;
  border-radius: var(--border-radius);
  overflow: hidden;
  background: linear-gradient(to bottom, #000, var(--color-333));
  box-shadow: 0 -2.44rem 0.24rem 0.73rem var(--color-white);
}

.menu .vip .top {
  padding: 0.24rem;
  border-bottom: 0.02rem solid var(--color-333);
  padding-bottom: 0.24rem;
}

.menu .vip .top .title {
  font-size: var(--size-14);
  color: var(--color-white);
  margin-bottom: 0.12rem;
  font-weight: bold;
}

.menu .vip .top .btn {
  color: #d3b78d;
  border-color: #d3b78d;
}

.menu .vip .bottom {
  padding: 0.24rem;
}

.menu .vip .bottom .img {
  border-radius: 100% !important;
  overflow: hidden;
}

.menu-list {
  background-color: var(--color-white);
  margin: 0.37rem;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.menu-list .adm-list-body {
  border-bottom: 0;
  border-top: 0;
}

.menu-list .adm-list-header {
  border-bottom: var(--border-bottom);
}
.player {
  min-height: 100vh;
  background-color: var(--color-ccc);
}

.player .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-position: center;
  background-size: 300%;
  background-repeat: no-repeat;
  -webkit-filter: blur(0.73rem);
  filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  overflow: hidden;
  background-color: var(--color-ccc);
}

.player-header {
  color: var(--color-white);
  width: 100%;
  z-index: 10;
  padding: 0.24rem 0.12rem;
}

.player-header .name {
  padding-bottom: 0.12rem;
}

.player-header .singer {
  font-size: var(--size-14);
}

.player-content {
  width: 100%;
  position: relative;
  z-index: 10;
}

.player-content .img-cover {
  width: 100%;
  margin-top: 0.24rem;
}

.player-content .img-cover .img {
  border-radius: 100%;
  overflow: hidden;
  animation: rotate-animate 60s linear infinite;
  animation-play-state: running;
}

.player-content .img-cover .img.pause {
  animation-play-state: paused;
}

.player-content .lyric-section {
  width: 100%;
  margin-top: 0.24rem;
  position: relative;
}

.player-content .lyric {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
}

.player-content .lyric-item {
  width: 100%;
  padding: 0 0.37rem;
  line-height: 0.59rem;
  margin-bottom: 0.24rem;
  flex-wrap: wrap;
  word-break: break-all;
  font-size: var(--size-16);
  color: var(--color-ccc);
}

.player-content .lyric-item.active {
  font-size: var(--size-22);
  color: var(--color-white);
}

.player-content .actions {
  width: 100%;
  height: 1.95rem;
  padding: 0 0.37rem;
  justify-content: space-between;
}

.player-content .actions .icon {
  font-size: 0.78rem;
  color: var(--color-white);
}

.player-content .actions .icon.heart {
  font-size: 0.68rem;
}

.player-content .actions .icon.heart.active {
  color: var(--adm-color-primary);
}

.player-content .progress-bar {
  width: 100%;
  padding: 0 0.07rem;
}

.player-content .progress-bar .time {
  margin-top: 0.12rem;
  padding: 0 0.37rem;
  font-size: var(--size-14);
  color: var(--color-white);
}

.player-content .progress-bar .adm-slider-fill,
.player-content .progress-bar .adm-slider-track {
  border-radius: 0.12rem;
}

@keyframes rotate-animate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(1turn);
  }
}
.player-footer {
  width: 100%;
  padding: 0 0.37rem;
  z-index: 10;
  height: 3.9rem;
  padding-bottom: 0.49rem;
}

.player-footer.fixed {
  background-color: var(--color-white);
  height: 1.46rem;
  width: 100%;
  padding-bottom: 0;
  border-top: 0.02rem solid var(--color-border);
}

.player-footer.fixed .icon {
  font-size: 0.54rem;
  margin-left: 0.54rem;
  color: var(--color-666);
}

.player-footer.fixed .play-icon {
  padding: 0.12rem 0.15rem;
  font-size: 0.44rem;
  border: 0.02rem solid var(--color-666);
}

.player-footer.fixed .img .adm-image {
  border-radius: 100%;
  overflow: hidden;
}

.player-footer.fixed-bottom {
  bottom: 0;
}

.player-footer .icon {
  font-size: 0.78rem;
  color: var(--color-white);
}

.player-footer .loop-icon,
.player-footer .list-icon {
  font-size: 0.59rem;
}

.player-footer .play-icon {
  padding: 0.37rem;
  line-height: 0.49rem;
  font-size: 0.49rem;
  border-radius: 100%;
  border: 0.05rem solid var(--color-white);
}

.player-footer .prev-icon,
.player-footer .next-icon {
  font-size: 0.68rem;
  position: relative;
}
.music {
  overflow: hidden;
}

.music-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
}

.music-header {
  background-color: var(--color-white);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  font-size: 0.49rem;
  color: var(--color-333);
  width: 100%;
  padding: 0.07rem 0.2rem;
}

.music-body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 2.73rem);
}

.music-body > div {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 0.37rem;
  text-align: center;
}

.music-footer {
  flex: 0;
  border-top: solid 0.02rem var(--adm-border-color);
}

.music-footer .adm-tab-bar-item-title {
  margin-top: 0.24rem;
}

.music-logo {
  height: 0.98rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .music .music-logo {
    animation: music-logo-spin infinite 20s linear;
  }
}

@keyframes music-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
