.my-userinfo .avatar {
  position: absolute;
  top: -1.1rem;
}

.my-userinfo .avatar .adm-avatar.adm-image {
  width: 1.76rem;
  height: 1.76rem;
}

.my-userinfo .info {
  margin: 0.88rem 0 0.2rem;
}

.my-userinfo .info .name {
  font-size: 0.54rem;
  margin-right: 0.24rem;
}

.my-userinfo .others > div {
  margin: 0 0.24rem;
  font-size: 0.34rem;
  color: #999;
}

.my-userinfo .others > div span {
  margin-right: 0.12rem;
}