.music {
  overflow: hidden;
}

.music-main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: hidden;
}

.music-header {
  background-color: var(--color-white);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  font-size: 0.49rem;
  color: var(--color-333);
  width: 100%;
  padding: 0.07rem 0.2rem;
}

.music-body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 2.73rem);
}

.music-body > div {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 0.37rem;
  text-align: center;
}

.music-footer {
  flex: 0;
  border-top: solid 0.02rem var(--adm-border-color);
}

.music-footer .adm-tab-bar-item-title {
  margin-top: 0.24rem;
}

.music-logo {
  height: 0.98rem;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .music .music-logo {
    animation: music-logo-spin infinite 20s linear;
  }
}

@keyframes music-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}