.userinfo-content {
  font-size: var(--size-14);
}

.userinfo-content .avatar {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.userinfo-content .userinfo {
  padding-right: 0.37rem;
}

.userinfo-content .time {
  font-size: var(--size-12);
  color: var(--color-999);
}

.userinfo-content .nickname {
  margin-bottom: 0.12rem;
  color: var(--color-666);
}