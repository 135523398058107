@import url('//at.alicdn.com/t/c/font_3971272_x6shjed0ac.css');

:root:root {
  --primary-color: #333;
  --adm-color-primary: #f54040;
  --color-333: #333;
  --color-666: #666;
  --color-999: #999;
  --color-ccc: #ccc;
  --color-f5: #f5f5f5;
  --color-f8: #f8f8f8;
  --color-e8: #e8e8e8;
  --color-border: #e8e8e8;
  --color-white: #fff;
  --border-radius: 0.2rem;
  --bg-color: #282c34;
  --size-12: 0.29rem;
  --size-14: 0.34rem;
  --size-16: 0.39rem;
  --size-18: 0.44rem;
  --size-20: 0.49rem;
  --size-22: 0.54rem;
  --size-24: 0.59rem;
  --height: 1.22rem;
  --pading: 0 0.37rem;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

p,
ul,
li,
ol,
dl,
dt {
  padding: 0;
  margin: 0;
}

/*对齐，浮动*/

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.pd16 {
  padding: 0.39rem !important;
}

.m0 {
  margin: 0 !important;
}

.m16 {
  margin: 0.39rem !important;
}

.mr16 {
  margin-right: 0.39rem !important;
}

.mt16 {
  margin-top: 0.39rem !important;
}

.mb16 {
  margin-bottom: 0.39rem !important;
}

.ml16 {
  margin-left: 0.39rem !important;
}

.text-j {
  text-align: justify;
}

/*文字超出隐藏*/

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsisTwo {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsisOne {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsisOne1 {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}

/*flex 横向 布局*/

.flexbox-h {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
}

/*flex 纵向 布局*/

.flexbox-v {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.align-c {
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.just-c {
  -webkit-box-justify-content: center;
  -moz-box-justify-content: center;
  -ms-flex-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.just-between {
  -webkit-box-justify-content: space-between;
  -moz-box-justify-content: space-between;
  -ms-flex-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.just-around {
  -webkit-box-justify-content: space-around;
  -moz-box-justify-content: space-around;
  -ms-flex-justify-content: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.flex1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  width: 0%;
}

.flex2 {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  flex: 2;
  width: 0%;
}

.flex3 {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  flex: 3;
  width: 0%;
}

.flex4 {
  -webkit-box-flex: 4;
  -webkit-flex: 4;
  flex: 4;
  width: 0%;
}