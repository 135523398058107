.home {
  align-items: flex-start;
}

.home .customIndicator {
  position: absolute;
  bottom: 0.15rem;
  right: 0.15rem;
  background: rgba(0, 0, 0, 0.3);
  padding: 0.1rem 0.2rem;
  color: #ffffff;
  border-radius: 0.1rem;
  user-select: none;
}

.home > .title {
  text-align: left;
  font-size: 0.44rem;
  padding: 0 0.37rem;
}

.home .customSkeleton {
  --width: calc(100% - 0.73rem);
  margin: 0 0.37rem;
  --height: 2.44rem;
  --border-radius: 0.2rem;
}