.play-list {
  padding-bottom: 0 !important;
}

.play-list-item {
  border-bottom: 0.05rem solid transparent;
  padding-bottom: 0.12rem;
  position: relative;
}

.play-list-item::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0.73rem;
  height: 0.07rem;
  border-radius: 0.24rem;
  background-color: transparent;
  margin-left: -0.37rem;
}

.play-list-item.active {
  color: var(--adm-color-primary);
}

.play-list-item.active::after {
  background-color: var(--adm-color-primary);
}