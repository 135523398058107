.player-footer {
  width: 100%;
  padding: 0 0.37rem;
  z-index: 10;
  height: 3.9rem;
  padding-bottom: 0.49rem;
}

.player-footer.fixed {
  background-color: var(--color-white);
  height: 1.46rem;
  width: 100%;
  padding-bottom: 0;
  border-top: 0.02rem solid var(--color-border);
}

.player-footer.fixed .icon {
  font-size: 0.54rem;
  margin-left: 0.54rem;
  color: var(--color-666);
}

.player-footer.fixed .play-icon {
  padding: 0.12rem 0.15rem;
  font-size: 0.44rem;
  border: 0.02rem solid var(--color-666);
}

.player-footer.fixed .img .adm-image {
  border-radius: 100%;
  overflow: hidden;
}

.player-footer.fixed-bottom {
  bottom: 0;
}

.player-footer .icon {
  font-size: 0.78rem;
  color: var(--color-white);
}

.player-footer .loop-icon,
.player-footer .list-icon {
  font-size: 0.59rem;
}

.player-footer .play-icon {
  padding: 0.37rem;
  line-height: 0.49rem;
  font-size: 0.49rem;
  border-radius: 100%;
  border: 0.05rem solid var(--color-white);
}

.player-footer .prev-icon,
.player-footer .next-icon {
  font-size: 0.68rem;
  position: relative;
}