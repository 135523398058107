.commit-list {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0!important;
}

.commit-list-top {
  width: 100%;
  padding: 0.12rem 0.37rem;
  font-size: var(--size-14);
  border-top: 0.02rem solid var(--color-border);
  border-bottom: 0.02rem solid var(--color-border);
  background-color: var(--color-white);
}

.commit-list-top .img {
  width: 0.98rem;
  height: 0.98rem;
  margin-right: 0.24rem;
  border-radius: 100%;
}

.commit-list-top .name {
  line-height: 1.5;
  margin-right: 0.12rem;
}

.commit-list-top .singer {
  margin-left: 0.12rem;
  color: var(--color-999);
}

.commit-list .music-commit {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.commit-list .adm-list-default .adm-list-body {
  border-top: 0;
  border-bottom: 0;
}

.commit-list .adm-list {
  --padding-left: 0.37rem !important;
}

.commit-list .adm-list .adm-list-item .adm-list-item-content {
  padding-right: 0;
  border-top: 0;
}

.commit-list .adm-list .adm-list-item .adm-list-item-content .adm-list-item-content-main {
  padding-bottom: 0;
}

.commit-list.repeat {
  height: 70vh;
}

.commit-list.repeat .repeated-user .adm-list-item {
  border-bottom: 0.24rem solid var(--color-f8);
}

.commit-list.repeat .music-commit-item .bottom {
  border-bottom: 0;
}