.menu {
  padding-bottom: 0.24rem;
}

.menu-main {
  height: calc(100vh - 1.22rem);
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 0.24rem;
  background-color: var(--color-f8);
}

.menu .user-info {
  height: 1.22rem;
  color: var(--color-333);
  font-size: var(--size-16);
  padding: 0 0.29rem;
  background-color: var(--color-white);
}

.menu .vip {
  margin: 0.37rem;
  color: #d3b78d;
  border-radius: var(--border-radius);
  overflow: hidden;
  background: linear-gradient(to bottom, #000, var(--color-333));
  box-shadow: 0 -2.44rem 0.24rem 0.73rem var(--color-white);
}

.menu .vip .top {
  padding: 0.24rem;
  border-bottom: 0.02rem solid var(--color-333);
  padding-bottom: 0.24rem;
}

.menu .vip .top .title {
  font-size: var(--size-14);
  color: var(--color-white);
  margin-bottom: 0.12rem;
  font-weight: bold;
}

.menu .vip .top .btn {
  color: #d3b78d;
  border-color: #d3b78d;
}

.menu .vip .bottom {
  padding: 0.24rem;
}

.menu .vip .bottom .img {
  border-radius: 100% !important;
  overflow: hidden;
}

.menu-list {
  background-color: var(--color-white);
  margin: 0.37rem;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.menu-list .adm-list-body {
  border-bottom: 0;
  border-top: 0;
}

.menu-list .adm-list-header {
  border-bottom: var(--border-bottom);
}