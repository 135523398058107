.song-list {
  padding-bottom: 0 !important;
}

.song-list .cover-main {
  margin-bottom: 0.37rem;
  min-height: 10rem;
  position: relative;
}

.song-list .cover-main .cover-text {
  position: absolute;
  bottom: 0;
  z-index: 10;
  padding: 0.37rem;
  width: 100%;
  color: var(--color-white);
  font-size: var(--size-12);
}

.song-list .cover-main .cover-text .line {
  width: 50%;
  color: var(--color-ccc);
  margin: 0 auto;
  margin-bottom: 0.12rem;
}

.song-list .cover-main .cover-text .name {
  font-size: var(--size-20);
  font-weight: bolder;
  margin-bottom: 0.24rem;
}

.song-list .cover-main .cover-text .description {
  margin-top: 0.37rem;
  color: var(--color-ccc);
}

.song-list .cover-main .cover-bg {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: rgba(117, 117, 117, 0.8);
}

.song-list .cover-main .img {
  width: auto;
  height: 100%;
  position: relative;
  z-index: 2;
}

.song-list .cover-main.custom-cover {
  min-height: 3.66rem;
  padding: 0 0 0 0.73rem;
  overflow: hidden;
}

.song-list .cover-main.custom-cover .cover-bg {
  border-radius: 0 0 50% 50%;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  -webkit-filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  filter: blur(0.73rem) drop-shadow(0.39rem 0.39rem 0.49rem #000) invert(40%);
  background-color: var(--color-ccc);
}

.song-list .cover-main.custom-cover .img {
  z-index: 10;
  width: 2.44rem;
  height: 2.44rem;
  border-radius: var(--border-radius);
}

.song-list .cover-main.custom-cover .cover-text {
  flex: 1;
  text-align: left;
  position: relative;
}

.song-list .cover-main.custom-cover .cover-text .line {
  display: none;
}

.song-list .cover-main.custom-cover .cover-text .name {
  font-size: var(--size-16);
}