.my {
  position: relative;
  padding-top: 0.61rem;
}

.my-play-list {
  width: 100%;
}

.my-play-list .list {
  width: 100%;
  margin-bottom: 0.37rem;
  border-radius: 0.29rem;
  box-shadow: 0 0 0.24rem #e8e8e8;
  overflow: hidden;
}

.my-play-list .list .adm-list-header {
  background-color: #fff;
  text-align: left;
}

.my-play-list .list-item {
  text-align: left;
  width: 100%;
}

.favirous {
  margin-top: 0.39rem;
  padding: 0 0.29rem;
}

.favirous .adm-list,
.favirous .adm-list-item,
.favirous .adm-list-item-content {
  --border-top: 0;
  --border-bottom: 0;
  --border-inner: 0;
  border-top: 0;
}

.favirous .adm-list .adm-list-item-content-main .adm-list-item-description,
.favirous .adm-list-item .adm-list-item-content-main .adm-list-item-description,
.favirous .adm-list-item-content .adm-list-item-content-main .adm-list-item-description {
  margin-top: 0.12rem;
}

.tabsContainer {
  position: -webkit-sticky;
  position: sticky;
  top: -0.66rem;
  z-index: 100;
  padding: 0 0.29rem;
  margin: 0.39rem 0 0.07rem;
  background: var(--color-f8);
}

.tabsContainer .adm-tabs {
  overflow: hidden;
  border-radius: 0.2rem;
}

.tabsContainer .adm-tabs .adm-tabs-header {
  border-bottom: 0;
}

.tabsContainer .adm-tabs .adm-tabs-tab-wrapper.active {
  color: var(--adm-color-primary);
}