.mv-detail {
  background-color: black;
}

.mv-detail .header {
  color: var(--adm-color-white);
}

.mv-detail .content {
  margin-top: 2.93rem;
  justify-content: space-between;
}

.mv-detail .bottom {
  width: 100%;
}

.mv-detail .info,
.mv-detail .text {
  width: 100%;
  padding: 0 0.37rem;
  text-align: left;
  margin-bottom: 0.24rem;
  justify-content: flex-start;
  line-height: 0.44rem;
  font-size: 0.39rem;
  color: var(--adm-color-white);
}

.mv-detail .info p,
.mv-detail .text p {
  margin-bottom: 0.12rem;
}

.mv-detail .info p.desc,
.mv-detail .text p.desc {
  max-height: 9.76rem;
}

.mv-detail .info .tag,
.mv-detail .text .tag {
  margin-right: 0.12rem;
}

.mv-detail .info .artistName,
.mv-detail .text .artistName {
  margin-left: 0.24rem;
}