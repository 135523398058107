.search {
  height: 100%;
}

.search.hidden {
  overflow: hidden !important;
}

.search-detail {
  padding-bottom: 0 !important;
}