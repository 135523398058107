.music-grid-item {
  position: relative;
  margin-bottom: 0;
}

.music-grid-item.active .text .name,
.music-grid-item.active .text .description {
  color: var(--adm-color-primary) !important;
}

.music-grid-item .name {
  width: 100%;
  color: var(--color-333);
  line-height: 0.44rem;
  font-size: var(--size-14);
  margin-top: 0.24rem;
  text-align: justify;
}

.music-grid-item .img {
  box-shadow: 0 -0.05rem 0.24rem 0.07rem var(--color-ccc);
  border-radius: 0.24rem;
  overflow: hidden;
  width: auto;
  height: auto;
  min-height: 1.71rem;
  background-color: var(--color-ccc);
}

.music-grid-item .img .adm-image-tip {
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item .tag {
  flex-basis: 0.49rem;
  color: var(--color-999);
}

.music-grid-item .play-count {
  position: absolute;
  right: 0.24rem;
  top: 0.12rem;
  border-radius: var(--border-radius);
  padding: 0 0.12rem;
  font-size: var(--size-12);
  color: var(--color-white);
  background: var(--color-999);
}

.music-grid-item .play-count .play-icon {
  margin-right: 0.05rem;
  font-size: 0.29rem;
}

.music-grid-item.type-1 .img {
  width: 100%;
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item.type-1 .img .adm-image-tip {
  height: auto;
  min-height: 1.71rem;
}

.music-grid-item.type-2 {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  padding-bottom: 0.12rem;
  border-bottom: 0.02rem solid var(--color-border);
}

.music-grid-item.type-2 .text {
  float: left;
  max-width: 8.29rem;
}

.music-grid-item.type-2 .text .name {
  margin-top: 0;
  line-height: 0.54rem;
  font-size: var(--size-18);
  color: var(--color-333);
}

.music-grid-item.type-2 .text .description {
  font-size: var(--size-12);
  vertical-align: middle;
  color: var(--color-999);
}

.music-grid-item.type-2 .img {
  float: left;
  margin-right: 0.24rem;
  min-height: 0.98rem;
  width: auto;
  height: auto;
}

.music-grid-item.type-2 .index {
  float: left;
  line-height: 0.98rem;
  font-weight: bold;
  font-size: var(--size-16);
  color: var(--color-999);
  margin-right: 0.24rem;
}

.music-grid .adm-grid-item:last-child .music-grid-item.type-2 {
  border-bottom: 0;
}