.swiper {
  width: calc(100% - 0.73rem);
  margin: 0 0.37rem 0.37rem;
}

.swiper-item {
  min-height: 2.93rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.17rem;
  user-select: none;
  position: relative;
}

.swiper-item .title {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 0.37rem;
  border-radius: var(--border-radius) 0 var(--border-radius) 0;
  font-size: var(--size-12);
  line-height: var(--size-20);
  color: var(--color-white);
  background-color: var(--primary-color);
}